export default {
  common: {
    requiredField: 'Field is required!',
    invalidEmail: 'Invalid email address!',
    id: 'Id',
    name: 'Name',
    email: 'Email address',
    password: 'Password',
    role: 'Role',
    roles: 'Roles',
    selectRole: 'Select role',
    createdAt: 'Created At',
    lastActiveDate: 'Last Active Date',
    save: 'Save',
    reset: 'Reset',
    filter: 'Filter',
    title: 'Title',
    notes: 'Notes',
    gold: 'Gold',
    status: 'Status',
    text: 'Text',
    author: 'Author',
    template: 'Template',
    goldPrice: 'Gold price',
    price: 'Price',
    free: 'Free',
    default: 'Default',
    new: 'New',
    type: 'Type',
    moreInfoUrl: 'More info url',
    invalidUrl: 'Invalid url!',
    invalidSvg: 'Invalid svg format!',
    createdBy: 'Created by',
    actions: 'Actions',
    selectStatus: 'Select status',
    selectTypes: 'Select type',
    approvalStatuses: {
      approved: 'Approved',
      pending: 'Pending',
      disapproved: 'Disapproved'
    },
    yes: 'Yes',
    no: 'No',
    loadingData: 'Data is loading...',
    noData: 'No data available',
    settings: 'Settings',
    imageUrl: 'Image URL',
    minLength: 'Minimum {value} characters',
    answer: 'Answer',
    answers: 'Answers',
    true: 'True',
    false: 'False',
    image: 'Image',
    thumbnailPath: 'Thumbnail path',
    categories: 'Categories',
    description: 'Description',
    promoPrice: 'Promo price',
    gems: 'Gems',
    sentBy: 'Sent by',
    lastSentDate: 'Last sent date',
    websiteUrl: 'Website Url'
  },
  languages: {
    bg: 'Български',
    en: 'English'
  },
  auth: {
    login: 'Login',
    signOut: 'Sign out',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    passwordMismatch: 'Password mismatch!',
    passwordChangedSuccessfully: 'Password changed successfully!'
  },
  users: {
    users: 'Users',
    update: 'Update user',
    roleChangedSuccessfully: 'Role changed successfully!',
    editProfile: 'Edit profile',
    editProfileSuccess: 'Profile updated successfully!',
    changePassword: 'Change password',
    lockConfirmText: 'Do you want to lock this user?',
    lockSuccess: 'User is locked successfully!',
    unlockConfirmText: 'Do you whant to unlock this user?',
    unlockSuccess: 'User is unlocked successfully!',
    deleteConfirmText: 'Do you want to delete this user?',
    deleteSuccess: 'User deleted successfully!'
  },
  roles: {
    admin: 'Admin',
    user: 'User'
  },
  tasks: {
    tasks: 'Tasks',
    createTask: 'Create task',
    editTask: 'Edit task',
    maxTagsLength: 'Maximum {number} tags!',
    taskTypes: {
      todo: 'To do',
      daily: 'Daily',
      weekly: 'Weekly'
    },
    selectType: 'Select type',
    createSuccess: 'Task created successfully!',
    updateSuccess: 'Task updated successfully!',
    deleteSuccess: 'Task deleted successfully!',
    deleteConfirmText: 'Do you want to delete this task?'
  },
  tags: {
    tags: 'Tags',
    work: 'Work',
    health: 'Health',
    sport: 'Sport',
    holiday: 'Holiday',
    school: 'School',
    fun: 'Fun',
    travel: 'Travel'
  },
  quotes: {
    quotes: 'Quotes',
    createQuote: 'Create quote',
    editQuote: 'Edit quote',
    createSuccess: 'Quote created successfully!',
    updateSuccess: 'Quote updated successfully!',
    deleteSuccess: 'Quote deleted successfully!',
    deleteConfirmText: 'Do you want to delete this quote?'
  },
  facts: {
    facts: 'Facts',
    createFact: 'Create fact',
    editFact: 'Edit fact',
    createSuccess: 'Fact created successfully!',
    updateSuccess: 'Fact updated successfully!',
    deleteSuccess: 'Fact deleted successfully!',
    deleteConfirmText: 'Do you want to delete this fact?'
  },
  specialBonuses: {
    specialBonuses: 'Special bonuses',
    createSpecialBonus: 'Create special bonus',
    editSpecialBonus: 'Edit special bonus',
    createSuccess: 'Special bonus created successfully!',
    updateSuccess: 'Special bonus updated successfully!',
    deleteSuccess: 'Special bonus deleted successfully!',
    deleteConfirmText: 'Do you want to delete this special bonus?'
  },
  questions: {
    questions: 'Questions',
    createQuestion: 'Create question',
    editQuestion: 'Edit question',
    createSuccess: 'Question created successfully!',
    updateSuccess: 'Question updated successfully!',
    deleteSuccess: 'Question deleted successfully!',
    deleteConfirmText: 'Do you want to delete this question?',
    backToQuestions: 'Back to questions',
    answers: {
      a: 'A',
      b: 'B',
      c: 'C'
    }
  },
  boards: {
    boards: 'Boards',
    createBoard: 'Create board',
    editBoard: 'Edit board',
    createSuccess: 'Board created successfully!',
    updateSuccess: 'Board updated successfully!',
    deleteSuccess: 'Board deleted successfully!',
    deleteConfirmText: 'Do you want to delete this board?',
    backToBoards: 'Back to boards',
    positonsCount: 'Positions count',
    addPositions: 'Add positions',
    positionTypes: {
      none: 'None',
      task: 'Task',
      fact: 'Fact',
      quote: 'Quote',
      question: 'Question',
      random: 'Random',
      bonus: 'Bonus',
      specialBonus: 'Special bonus'
    }
  },
  figures: {
    figures: 'Figures',
    createFigure: 'Create figure',
    editFigure: 'Edit figure',
    createSuccess: 'Figure created successfully!',
    updateSuccess: 'Figure updated successfully!',
    deleteSuccess: 'Figure deleted successfully!',
    deleteConfirmText: 'Do you want to delete this figure?'
  },
  avatars: {
    avatars: 'Avatars',
    createAvatar: 'Create avatar',
    editAvatar: 'Edit avatar',
    createSuccess: 'Avatar created successfully!',
    updateSuccess: 'Avatar updated successfully!',
    deleteSuccess: 'Avatar deleted successfully!',
    deleteConfirmText: 'Do you want to delete this avatar?',
    backToAvatars: 'Back to avatars',
    maxCategoriesLength: 'Max {number} categories!'
  },
  avatarCategories: {
    categories: 'Avatar categories',
    createCategory: 'Create category',
    editCategory: 'Edit category',
    createSuccess: 'Category created successfully!',
    updateSuccess: 'Category updated successfully!',
    deleteSuccess: 'Category deleted successfully!',
    deleteConfirmText: 'Do you want to delete this category?'
  },
  gemsPlans: {
    gemsPlans: 'Gems Plans',
    createPlan: 'Create plan',
    editPlan: 'Edit plan',
    createSuccess: 'Plan created successfully!',
    updateSuccess: 'Plan updated successfully!',
    deleteSuccess: 'Plan deleted successfully!',
    deleteConfirmText: 'Do you want to delete this plan?'
  },
  pushNotifications: {
    pushNotifications: 'Push Notifications',
    createPushNotification: 'Create push notification',
    editPushNotification: 'Edit push notification',
    createSuccess: 'Push notification created successfully!',
    updateSuccess: 'Push notification updated successfully!',
    deleteSuccess: 'Push notification deleted successfully!',
    deleteConfirmText: 'Do you want to delete this push notification?',
    sendNotification: 'Send notification',
    sendConfirmText:
      'Do you want to send the notification to all users? This operation may take more time!',
    cannotSendInProgressNotification:
      'Cannot send push notification which is In Progress',
    backToPushNotifications: 'Back to push notifications'
  },
  errors: {
    somethingWentWrong:
      'We are experiencing technical issues. Our team is working on them. Thank you for your patience!',
    wrongCredentials: 'Invalid email address / password!',
    invalidData: 'Invalid data!',
    notAuthorized: 'Not authorized!',
    cannotChangeSuperAdminRole: 'Cannot change super admin role!',
    passwordsMismatch: 'Passwords mismatch!',
    userNotFound: 'User was not found!',
    incorrectOldPassword: 'The old password is wrong!',
    invalidQueryParams: 'Invalid query params!',
    cannotDeleteSuperAdminUser: 'Cannot delete super admin user!',
    yourAccountIsLocked:
      'Your account is locked! Please contact our support team for more information.',
    cannotLockSuperAdminUser: 'Cannot lock super admin user!',
    userAlreadyLocked: 'User already locked!',
    userIsNotLocked: 'User is not locked!',
    alreadyExistDefaultBoard: 'Already exist default board!',
    gamPushNotificationNotFound: 'Push notification not found!',
    cannotUpdateInProgressNotification:
      'Cannot update push notification which is In Progress',
    cannotDeleteInProgressNotification:
      'Cannot delete push notification which is In Progress',
    cannotSendInProgressNotification:
      'Cannot send push notification which is In Progress'
  }
};
