export default [
  {
    path: '/users',
    name: 'users',
    meta: {
      auth: true
    },
    component: () => import('../pages/UsersPage.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      auth: true
    },
    component: () => import('../pages/SettingsPage.vue')
  }
];
