export default [
  {
    path: '/gems-plans',
    name: 'gems-plans',
    meta: {
      auth: true
    },
    component: () => import('../pages/GemsPlansPage')
  }
];
