import { SET_QUESTIONS, DELETE_QUESTION } from './types';

export default {
  [SET_QUESTIONS](state, payload) {
    state.questions = payload.questions;
    state.totalQuestions = payload.totalCount;
  },
  [DELETE_QUESTION](state, id) {
    const index = state.questions.findIndex(t => t.id === id);

    if (index !== -1) {
      state.questions.splice(index, 1);
    }
  }
};
