import { SET_QUOTES, UPDATE_QUOTE, DELETE_QUOTE } from './types';

export default {
  [SET_QUOTES](state, payload) {
    state.quotes = payload.quotes;
    state.totalQuotes = payload.totalCount;
  },
  [UPDATE_QUOTE](state, quote) {
    const index = state.quotes.findIndex(q => q.id === quote.id);

    if (index !== -1) {
      state.quotes.splice(index, 1, {
        ...state.quotes[index],
        ...quote
      });
    }
  },
  [DELETE_QUOTE](state, id) {
    const index = state.quotes.findIndex(t => t.id === id);

    if (index !== -1) {
      state.quotes.splice(index, 1);
    }
  }
};
