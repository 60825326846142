function getItem(key) {
  return localStorage.getItem(key);
}

function setItem(key, payload) {
  return localStorage.setItem(key, payload);
}

function removeItem(key) {
  return localStorage.removeItem(key);
}

export default {
  getItem,
  setItem,
  removeItem
};
