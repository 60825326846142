import {
  GET_ACL,
  SET_ACL,
  GET_USERS,
  SET_USERS,
  CHANGE_ROLE,
  LOCK_USER,
  UNLOCK_USER,
  UPDATE_USER,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  DELETE_USER
} from './types';
import usersHttpService from '../services/users.http.service';
import aclHttpService from '../services/acl.http.service';

export default {
  async [GET_ACL]({ commit }) {
    const { result } = await aclHttpService.index();

    commit(SET_ACL, result);
  },
  async [GET_USERS]({ commit }, params) {
    const { result } = await usersHttpService.index(params);

    commit(SET_USERS, result);
  },
  async [CHANGE_ROLE]({ commit }, payload) {
    await usersHttpService.changeRole(payload.id, { role: payload.role });

    commit(UPDATE_USER, payload);
  },
  async [LOCK_USER]({ commit }, id) {
    await usersHttpService.lock(id);

    commit(UPDATE_USER, { id, locked: true });
  },
  async [UNLOCK_USER]({ commit }, id) {
    await usersHttpService.unlock(id);

    commit(UPDATE_USER, { id, locked: false });
  },
  async [UPDATE_PROFILE]({ commit }, payload) {
    await usersHttpService.updateProfile(payload);

    commit(UPDATE_PROFILE, payload);
  },
  async [CHANGE_PASSWORD](actions, payload) {
    await usersHttpService.changePassword(payload);
  },
  async [DELETE_USER]({ commit }, payload) {
    await usersHttpService.del(payload);

    commit(DELETE_USER, payload);
  }
};
