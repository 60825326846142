export default [
  {
    path: '/avatars',
    name: 'avatars',
    meta: {
      auth: true
    },
    component: () => import('../pages/AvatarsPage.vue')
  },
  {
    path: '/avatars/create',
    name: 'create-avatar',
    meta: {
      auth: true
    },
    component: () => import('../pages/ManageAvatarPage.vue')
  },
  {
    path: '/avatars/:id/edit',
    name: 'edit-avatar',
    meta: {
      auth: true
    },
    component: () => import('../pages/ManageAvatarPage.vue')
  }
];
