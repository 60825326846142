<template>
  <v-dialog v-model="visible" v-bind="modalProps">
    <component
      :is="component"
      v-bind="componentProps"
      @close="close"
    ></component>
  </v-dialog>
</template>

<script>
export default {
  name: 'app-modal',
  data() {
    return {
      visible: false,
      component: null,
      resolve: null,
      reject: null,
      modalProps: {
        width: '600px',
        persistent: true
      },
      componentProps: {}
    };
  },
  methods: {
    show(component, componentProps = {}, modalProps = {}) {
      this.visible = true;
      this.component = component;
      this.componentProps = componentProps;
      this.modalProps = { ...this.modalProps, ...modalProps };

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close(data) {
      this.visible = false;
      this.component = null;
      this.resolve(data);
    }
  }
};
</script>
