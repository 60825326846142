export default [
  {
    path: '/push-notifications',
    name: 'push-notifications',
    meta: {
      auth: true
    },
    component: () => import('../pages/PushNotificationsPage.vue')
  },
  {
    path: '/push-notifications/create',
    name: 'create',
    meta: {
      auth: true
    },
    component: () => import('../pages/ManagePushNotificationPage.vue')
  },
  {
    path: '/push-notifications/:id/edit',
    name: 'edit-push-notification',
    meta: {
      auth: true
    },
    component: () => import('../pages/ManagePushNotificationPage.vue')
  }
];
