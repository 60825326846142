import {
  SET_SPECIAL_BONUSES,
  UPDATE_SPECIAL_BONUS,
  DELETE_SPECIAL_BONUS
} from './types';

export default {
  [SET_SPECIAL_BONUSES](state, payload) {
    state.specialBonuses = payload.specialBonuses;
    state.totalSpecialBonuses = payload.totalCount;
  },
  [UPDATE_SPECIAL_BONUS](state, specialBonus) {
    const index = state.specialBonuses.findIndex(s => s.id === specialBonus.id);

    if (index !== -1) {
      state.specialBonuses.splice(index, 1, {
        ...state.specialBonuses[index],
        ...specialBonus
      });
    }
  },
  [DELETE_SPECIAL_BONUS](state, id) {
    const index = state.specialBonuses.findIndex(s => s.id === id);

    if (index !== -1) {
      state.specialBonuses.splice(index, 1);
    }
  }
};
