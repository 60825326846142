const navItems = {
  users: {
    path: '/users',
    textKey: 'users.users',
    icon: 'mdi-account'
  },
  gamTasks: {
    path: '/tasks',
    textKey: 'tasks.tasks',
    icon: 'mdi-clipboard-list-outline'
  },
  quotes: {
    path: '/quotes',
    textKey: 'quotes.quotes',
    icon: 'mdi-comment-quote-outline'
  },
  facts: {
    path: '/facts',
    textKey: 'facts.facts',
    icon: 'mdi-glasses'
  },
  questions: {
    path: '/questions',
    textKey: 'questions.questions',
    icon: 'mdi-help-circle-outline'
  },
  specialBonuses: {
    path: '/special-bonuses',
    textKey: 'specialBonuses.specialBonuses',
    icon: 'mdi-gift'
  },
  boards: {
    path: '/boards',
    textKey: 'boards.boards',
    icon: 'mdi-view-dashboard'
  },
  avatarCategories: {
    path: '/avatar-categories',
    textKey: 'avatarCategories.categories',
    icon: 'mdi-badge-account-horizontal-outline'
  },
  avatars: {
    path: '/avatars',
    textKey: 'avatars.avatars',
    icon: 'mdi-account'
  },
  gemsPlans: {
    path: '/gems-plans',
    textKey: 'gemsPlans.gemsPlans',
    icon: 'mdi-view-week'
  },
  figures: {
    path: '/figures',
    textKey: 'figures.figures',
    icon: 'mdi-chess-queen'
  },
  gamPushNotifications: {
    path: '/push-notifications',
    textKey: 'pushNotifications.pushNotifications',
    icon: 'mdi-bell'
  }
};

function getNavItems(acl) {
  const result = [];
  const items = Object.entries(navItems);

  for (const [key, value] of items) {
    if (acl[key] && acl[key].indexOf('r') > -1) {
      result.push(value);
    }
  }

  return result;
}

export default {
  getNavItems
};
