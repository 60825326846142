import { GET_QUESTIONS, SET_QUESTIONS, DELETE_QUESTION } from './types';
import questionsHttpService from '../services/questions.http.service';

export default {
  async [GET_QUESTIONS]({ commit }, params) {
    const { result } = await questionsHttpService.index(params);

    commit(SET_QUESTIONS, result);
  },
  async [DELETE_QUESTION]({ commit }, payload) {
    await questionsHttpService.del(payload);

    commit(DELETE_QUESTION, payload);
  }
};
