export default [
  {
    path: '/avatar-categories',
    name: 'avatar-categories',
    meta: {
      auth: true
    },
    component: () => import('../pages/AvatarCategoriesPage')
  }
];
