export default [
  {
    path: '/boards',
    name: 'boards',
    meta: {
      auth: true
    },
    component: () => import('../pages/BoardsPage.vue')
  },
  {
    path: '/boards/create',
    name: 'create',
    meta: {
      auth: true
    },
    component: () => import('../pages/ManageBoardPage.vue')
  },
  {
    path: '/boards/:id/edit',
    name: 'edit-board',
    meta: {
      auth: true
    },
    component: () => import('../pages/ManageBoardPage.vue')
  }
];
