import {
  GET_QUOTES,
  SET_QUOTES,
  ADD_QUOTE,
  UPDATE_QUOTE,
  DELETE_QUOTE
} from './types';
import quotesHttpService from '../services/quotes.http.service';

export default {
  async [GET_QUOTES]({ commit }, params) {
    const { result } = await quotesHttpService.index(params);

    commit(SET_QUOTES, result);
  },
  async [ADD_QUOTE](actions, payload) {
    await quotesHttpService.create(payload);
  },
  async [UPDATE_QUOTE]({ commit }, payload) {
    const { id, ...quote } = payload;

    await quotesHttpService.update(id, quote);
    commit(UPDATE_QUOTE, payload);
  },
  async [DELETE_QUOTE]({ commit }, payload) {
    await quotesHttpService.del(payload);

    commit(DELETE_QUOTE, payload);
  }
};
