export default [
  {
    path: '/special-bonuses',
    name: 'specialBonuses',
    meta: {
      auth: true
    },
    component: () => import('../pages/SpecialBonusesPage.vue')
  }
];
