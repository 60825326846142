export default [
  {
    path: '/questions',
    name: 'questions',
    meta: {
      auth: true
    },
    component: () => import('../pages/QuestionsPage.vue')
  },
  {
    path: '/questions/create',
    name: 'create-question',
    meta: {
      auth: true
    },
    component: () => import('../pages/ManageQuestionPage.vue')
  },
  {
    path: '/questions/:id/edit',
    name: 'edit-question',
    meta: {
      auth: true
    },
    component: () => import('../pages/ManageQuestionPage.vue')
  }
];
