export const SET_PROFILE = 'SET_PROFILE';
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const CHANGE_ROLE = 'CHANGE_ROLE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const LOCK_USER = 'LOCK_USER';
export const UNLOCK_USER = 'UNLOCK_USER';
export const DELETE_USER = 'DELETE_USER';

export const GET_ACL = 'GET_ACL';
export const SET_ACL = 'SET_ACL';
