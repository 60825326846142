export default [
  {
    path: '/tasks',
    name: 'tasks',
    meta: {
      auth: true
    },
    component: () => import('../pages/TasksPage.vue')
  }
];
