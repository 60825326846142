export default [
  {
    path: '/figures',
    name: 'figures',
    meta: {
      auth: true
    },
    component: () => import('../pages/FiguresPage.vue')
  }
];
