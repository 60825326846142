import httpService from '../../../core/services/http.service';

const BASE_URL = '/admin/users';
const PROFILE_URL = `${BASE_URL}/profile`;

function index(params) {
  return httpService.get(BASE_URL, params);
}

function changeRole(id, payload) {
  return httpService.patch(`${BASE_URL}/${id}/role`, payload);
}

function lock(id) {
  return httpService.patch(`${BASE_URL}/${id}/lock`, {});
}

function unlock(id) {
  return httpService.patch(`${BASE_URL}/${id}/unlock`, {});
}

function updateProfile(payload) {
  return httpService.put(PROFILE_URL, payload);
}

function changePassword(payload) {
  return httpService.put(`${PROFILE_URL}/change-password`, payload);
}

function del(id) {
  return httpService.del(`${BASE_URL}/${id}`);
}

export default {
  index,
  changeRole,
  updateProfile,
  changePassword,
  lock,
  unlock,
  del
};
