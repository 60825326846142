import httpService from '../../../core/services/http.service';

const BASE_URL = '/acl';

function index() {
  return httpService.get(BASE_URL);
}

export default {
  index
};
