export default [
  {
    path: '/',
    name: 'home',
    meta: {
      public: true
    },
    component: () => import('../pages/HomePage.vue')
  }
];
