import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import authSession from '../../../core/services/auth.session.service';

const state = {
  currentUser: authSession.getUser() || {},
  acl: [],
  users: [],
  totalUsers: 0
};

export default {
  state,
  actions,
  mutations,
  getters
};
