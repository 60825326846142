export default [
  {
    path: '/facts',
    name: 'facts',
    meta: {
      auth: true
    },
    component: () => import('../pages/FactsPage.vue')
  }
];
