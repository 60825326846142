import Vue from 'vue';
import Vuex from 'vuex';
import shared from '../modules/shared/store';
import auth from '../modules/auth/store';
import users from '../modules/users/store';
import tasks from '../modules/tasks/store';
import tags from '../modules/tags/store';
import quotes from '../modules/quotes/store';
import facts from '../modules/facts/store';
import questions from '../modules/questions/store';
import specialBonuses from '../modules/special-bonuses/store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    shared,
    auth,
    users,
    tasks,
    tags,
    quotes,
    facts,
    questions,
    specialBonuses
  }
});
