import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  facts: [],
  totalFacts: 0
};

export default {
  state,
  actions,
  mutations,
  getters
};
