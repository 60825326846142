import { LOGIN, LOGOUT, AUTHENTICATE, RESET_PASSWORD } from './types';
import { SET_PROFILE } from '../../users/store/types';
import authService from '../services/auth.http.service';
import authSession from '../../../core/services/auth.session.service';
import languages from '../../../enums/languages';

function addToLocalStorage(data) {
  authSession.setToken(data.token);
  authSession.setUser(data.user);
  authSession.setLang(data.user.lang || languages.en);
}

export default {
  async [LOGIN]({ commit }, payload) {
    const { result } = await authService.login(payload);

    addToLocalStorage(result);
    commit(AUTHENTICATE);
    commit(SET_PROFILE, result.user);

    return result;
  },
  [RESET_PASSWORD](actions, payload) {
    return authService.resetPassword(payload);
  },
  async [LOGOUT]({ commit }) {
    await authService.logout();
    authSession.removeToken();
    authSession.removeUser();
    window.location.href = '/';
    commit(LOGOUT);
  }
};
