export default {
  currentUser(state) {
    return state.currentUser;
  },
  users(state) {
    return state.users;
  },
  acl(state) {
    return state.acl;
  },
  totalUsers(state) {
    return state.totalUsers;
  }
};
