import { SET_FACTS, UPDATE_FACT, DELETE_FACT } from './types';

export default {
  [SET_FACTS](state, payload) {
    state.facts = payload.facts;
    state.totalFacts = payload.totalCount;
  },
  [UPDATE_FACT](state, fact) {
    const index = state.facts.findIndex(q => q.id === fact.id);

    if (index !== -1) {
      state.facts.splice(index, 1, {
        ...state.facts[index],
        ...fact
      });
    }
  },
  [DELETE_FACT](state, id) {
    const index = state.facts.findIndex(t => t.id === id);

    if (index !== -1) {
      state.facts.splice(index, 1);
    }
  }
};
