import Vue from 'vue';
import dataTimeManager from '../services/date.time.manager';
import amountFormatterService from '../../modules/shared/services/amount.formatter.service';

Vue.filter('truncate', (value, length) => {
  if (!value) {
    return '';
  }

  return `${value.substring(0, length)}${value.length > length ? '...' : ''}`;
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return dataTimeManager.formatDate(value, 'DD.MM.YYYY HH:mm');
  }
});

Vue.filter('amount', value => {
  if (!value) {
    return '';
  }

  return amountFormatterService.formatDisplayValue(value);
});
