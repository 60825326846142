import {
  GET_FACTS,
  SET_FACTS,
  ADD_FACT,
  UPDATE_FACT,
  DELETE_FACT
} from './types';
import factsHttpService from '../services/facts.http.service';

export default {
  async [GET_FACTS]({ commit }, params) {
    const { result } = await factsHttpService.index(params);

    commit(SET_FACTS, result);
  },
  async [ADD_FACT](actions, payload) {
    await factsHttpService.create(payload);
  },
  async [UPDATE_FACT]({ commit }, payload) {
    const { id, ...fact } = payload;

    await factsHttpService.update(id, fact);
    commit(UPDATE_FACT, payload);
  },
  async [DELETE_FACT]({ commit }, payload) {
    await factsHttpService.del(payload);

    commit(DELETE_FACT, payload);
  }
};
