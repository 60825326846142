import authSession from '../services/auth.session.service';

export default (to, from, next) => {
  if (to.matched.some(record => record.meta.public)) {
    if (authSession.isAuthenticated()) {
      next({
        path: '/users'
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.auth)) {
    if (!authSession.isAuthenticated()) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
};
