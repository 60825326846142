<template>
  <v-navigation-drawer v-if="hasSideNav" fixed clipped app floating>
    <v-list-item class="my-2">
      <v-list-item-avatar>
        <v-img v-if="currentUser.imageUrl" :src="currentUser.imageUrl"></v-img>
        <v-img v-else :src="blankProfileImg"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ currentUser.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list>
      <v-list-item
        link
        v-for="item of navItems"
        :key="item.path"
        :to="item.path"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t(item.textKey) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_ACL } from '../../users/store/types';
import sidebarService from '../services/sidebar.service';

export default {
  name: 'side-nav',
  computed: {
    ...mapGetters(['hasSideNav', 'currentUser', 'acl'])
  },
  data() {
    return {
      navItems: [],
      blankProfileImg: require('../../../assets/images/blank-profile.png')
    };
  },
  async created() {
    await this.$store.dispatch(GET_ACL);
    this.navItems = sidebarService.getNavItems(this.acl);
  }
};
</script>
