const FRACTION_SIZE = 2;

function getZerosByNumber(numberOfZeros) {
  let zeros = '';

  for (let i = 0; i < numberOfZeros; i++) {
    zeros += '0';
  }
  return zeros;
}

function formatDisplayValue(value) {
  if (!value && !Number.isInteger(value)) {
    return '';
  }

  let result = (value / Math.pow(10, FRACTION_SIZE)).toFixed(FRACTION_SIZE);

  result = result.replace('.', ',');

  return result;
}

function formatAmountValue(payload) {
  let amount = payload;

  if (amount) {
    amount = payload.replace('.', ',');
  }

  if (!Number.isInteger(amount)) {
    const splittedAmount = amount.split(',');
    let numberOfZeros = FRACTION_SIZE;

    if (splittedAmount.length > 1) {
      numberOfZeros -= splittedAmount[1].length;
    }

    amount = `${amount.replace(',', '')}${getZerosByNumber(numberOfZeros)}`;
    amount = parseInt(amount);
  }

  return amount;
}

export default {
  formatDisplayValue,
  formatAmountValue
};
