import httpService from '../../../core/services/http.service';

function login(payload) {
  return httpService.post('/admin/auth/login', payload);
}

function resetPassword(payload) {
  return httpService.post('/admin/auth/reset-password', payload);
}

function logout() {
  return httpService.post('/admin/auth/logout');
}

export default {
  login,
  resetPassword,
  logout
};
