export default [
  {
    path: '/quotes',
    name: 'quotes',
    meta: {
      auth: true
    },
    component: () => import('../pages/QuotesPage.vue')
  }
];
