<template>
  <v-app-bar app color="primary" dark clipped-left class="app-nav">
    <v-app-bar-nav-icon @click="toggleSideNav()"></v-app-bar-nav-icon>
    <v-toolbar-title class="pl-1">Gamifinnopoly Admin</v-toolbar-title>
    <div class="flex-grow-1"></div>
    <v-toolbar-items>
      <v-menu offset-y transition="slide-x-transition">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon>mdi-account</v-icon>
            <v-icon right small class="ml-0">mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item router to="/settings">
            <v-list-item-title>
              <v-icon left>mdi-cog</v-icon>
              {{ $t('common.settings') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item router @click="logout()">
            <v-list-item-title>
              <v-icon left>mdi-exit-to-app</v-icon>
              {{ $t('auth.signOut') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { LOGOUT } from '../../auth/store/types';
import { TOGGLE_SIDE_NAV } from '../store/types';

export default {
  name: 'app-navbar',
  methods: {
    toggleSideNav() {
      this.$store.dispatch(TOGGLE_SIDE_NAV);
    },
    logout() {
      this.$store.dispatch(LOGOUT);
    }
  }
};
</script>
