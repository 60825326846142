import { AUTHENTICATE, LOGOUT } from './types';

export default {
  [AUTHENTICATE](state) {
    state.isAuthenticated = true;
  },
  [LOGOUT](state) {
    state.isAuthenticated = false;
  }
};
