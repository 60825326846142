import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import authSession from '../../../core/services/auth.session.service';

const state = {
  isAuthenticated: authSession.isAuthenticated() || false
};

export default {
  state,
  actions,
  mutations,
  getters
};
