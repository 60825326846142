import {
  GET_SPECIAL_BONUSES,
  SET_SPECIAL_BONUSES,
  ADD_SPECIAL_BONUS,
  UPDATE_SPECIAL_BONUS,
  DELETE_SPECIAL_BONUS
} from './types';
import specialBonusesHttpService from '../services/special.bonuses.http.service';

export default {
  async [GET_SPECIAL_BONUSES]({ commit }, params) {
    const { result } = await specialBonusesHttpService.index(params);

    commit(SET_SPECIAL_BONUSES, result);
  },
  async [ADD_SPECIAL_BONUS](actions, payload) {
    await specialBonusesHttpService.create(payload);
  },
  async [UPDATE_SPECIAL_BONUS]({ commit }, payload) {
    const { id, ...specialBonus } = payload;

    await specialBonusesHttpService.update(id, specialBonus);
    commit(UPDATE_SPECIAL_BONUS, payload);
  },
  async [DELETE_SPECIAL_BONUS]({ commit }, payload) {
    await specialBonusesHttpService.del(payload);

    commit(DELETE_SPECIAL_BONUS, payload);
  }
};
