<template>
  <v-app-bar app color="primary" dark>
    <v-toolbar-title>Gamifinnopoly Admin</v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'public-navbar'
};
</script>
