export default {
  common: {
    requiredField: 'Полето е задължително!`',
    invalidEmail: 'Невалиден имейл адрес!',
    id: 'Идентификационен номер',
    name: 'Име',
    email: 'Електронна поща',
    password: 'Парола',
    role: 'Роля',
    roles: 'Роли',
    selectRole: 'Изберете роля',
    createdAt: 'Създаден на',
    lastActiveDate: 'Активен на',
    save: 'Запиши',
    reset: 'Изчисти',
    filter: 'Филтрирай',
    title: 'Заглавие',
    notes: 'Бележки',
    goldPrice: 'Цена в злато',
    gold: 'Злато',
    status: 'Статус',
    text: 'Текст',
    author: 'Автор',
    template: 'Шаблон',
    price: 'Цена',
    free: 'Безплатен',
    default: 'Подразбиране',
    new: 'Нов',
    type: 'Тип',
    moreInfoUrl: 'Повече информация линк',
    invalidUrl: 'Невалиден адрес!',
    invalidSvg: 'Невалиден svg формат!',
    createdBy: 'Създадена от',
    actions: 'Действия',
    selectStatus: 'Избери статус',
    selectTypes: 'Избери тип',
    approvalStatuses: {
      approved: 'Одобрен',
      pending: 'Чакащ',
      disapproved: 'Неодобрен'
    },
    yes: 'Да',
    no: 'Не',
    loadingData: 'Данните се зареждат...',
    noData: 'Липсват данни',
    settings: 'Настройки',
    imageUrl: 'Адрес на снимка',
    minLength: 'Минимално {value} символа',
    answer: 'Отговор',
    answers: 'Отговори',
    true: 'Истина',
    false: 'Лъжа',
    image: 'Картинка',
    thumbnailPath: 'Малка картинка',
    categories: 'Категории',
    description: 'Описание',
    promoPrice: 'Промо цена',
    gems: 'Gems бройка',
    sentBy: 'Изпратена от',
    lastSentDate: 'Изпратена на',
    websiteUrl: 'Уебсайт адрес'
  },
  languages: {
    bg: 'Български',
    en: 'English'
  },
  auth: {
    login: 'Вход',
    signOut: 'Изход',
    oldPassword: 'Стара парола',
    newPassword: 'Нова парола',
    confirmNewPassword: 'Потвъди новата парола',
    passwordMismatch: 'Паролата не съвпада!',
    passwordChangedSuccessfully: 'Паролата беше сменена успешно!'
  },
  users: {
    users: 'Потребители',
    update: 'Промяна на потребител',
    roleChangedSuccessfully: 'Ролята е променена успешно!',
    editProfile: 'Промяна на профила',
    editProfileSuccess: 'Променихте профила си успешно!',
    changePassword: 'Промяна на паролата',
    lockConfirmText: 'Сигурни ли сте, че искате да заключите потребителя!',
    lockSuccess: 'Потребителят е заключен успешно!',
    unlockConfirmText: 'Сигурни ли сте, че искате да отключите потребителя!',
    unlockSuccess: 'Потребителят е отключен успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете този потребител?',
    deleteSuccess: 'Потребителят е изтрит успешно!'
  },
  roles: {
    admin: 'Админ',
    user: 'Потребител'
  },
  tasks: {
    tasks: 'Задачи',
    maxTagsLength: 'Максимално {number} тага!',
    createTask: 'Добави задача',
    editTask: 'Промени задача',
    deleteTaskConfirm: 'Сигурни ли сте, че искате да изтриете задачата?',
    taskTypes: {
      todo: 'Веднъж',
      daily: 'Дневна',
      weekly: 'Седмична'
    },
    selectType: 'Избери тип',
    createSuccess: 'Задачата е създадена успешно!',
    updateSuccess: 'Задачата е променена успешно!',
    deleteSuccess: 'Задачата е изтрита успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете тази задача?'
  },
  tags: {
    tags: 'Тагове',
    work: 'Работа',
    health: 'Здраве',
    sport: 'Спорт',
    holiday: 'Почивка',
    school: 'Училище',
    fun: 'Забавление',
    travel: 'Пътуване'
  },
  quotes: {
    quotes: 'Цитати',
    createQuote: 'Добави цитат',
    editQuote: 'Промени цитат',
    createSuccess: 'Цитатът е създаден успешно!',
    updateSuccess: 'Цитатът е променен успешно!',
    deleteSuccess: 'Цитатът е изтрит успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете този цитат?'
  },
  facts: {
    facts: 'Факти',
    createFact: 'Добави факт',
    editFact: 'Промени факт',
    createSuccess: 'Фактът е създаден усепшно!',
    updateSuccess: 'Фактът е променен успешно!',
    deleteSuccess: 'Фактът е изтрит успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете този цитат?'
  },
  specialBonuses: {
    specialBonuses: 'Специален бонус',
    createSpecialBonus: 'Create специален бонус',
    editSpecialBonus: 'Edit специален бонус',
    createSuccess: 'Специалния бонус е създаден успешно!',
    updateSuccess: 'Специалния бонус е променен успешно!',
    deleteSuccess: 'Специалния бонус е изтрит успешно!',
    deleteConfirmText:
      'Сигурни ли сте, че искате да изтриете този специален бонус?'
  },
  questions: {
    questions: 'Въпроси',
    createQuestion: 'Добави въпрос',
    editQuestion: 'Промени въпрос',
    createSuccess: 'Въпросът е създаден успешно!',
    updateSuccess: 'Въпросът е променен успешно!',
    deleteSuccess: 'Въпросът е изтрит успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете този въпрос?',
    backToQuestions: 'Към всички въпроси',
    answers: {
      a: 'А',
      b: 'Б',
      c: 'В'
    }
  },
  boards: {
    boards: 'Борд',
    createBoard: 'Добави борд',
    editBoard: 'Промени борд',
    createSuccess: 'Бордът е създаден успешно!',
    updateSuccess: 'Бордът е променен успешно!',
    deleteSuccess: 'Бордът е изтрит успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете борда?',
    backToBoards: 'Към бордовете',
    positonsCount: 'Брой позиции',
    addPositions: 'Добави позиция',
    positionTypes: {
      none: 'Няма',
      task: 'Задача',
      fact: 'Факт',
      quote: 'Цитат',
      question: 'Въпрос',
      random: 'Случайна',
      bonus: 'Бонус',
      specialBonus: 'Специален бонус'
    }
  },
  figures: {
    figures: 'Фигури',
    createFigure: 'Добави фигура',
    editFigure: 'Промени фигурата',
    createSuccess: 'Фигурата е създаден усепшно!',
    updateSuccess: 'Фигурата е променен успешно!',
    deleteSuccess: 'Фигурата е изтрит успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете тази фигура?'
  },
  avatars: {
    avatars: 'Аватари',
    createAvatar: 'Добави аватар',
    editAvatar: 'Промени аватара',
    createSuccess: 'Аватарът е създаден усепшно!',
    updateSuccess: 'Аватарът е променен успешно!',
    deleteSuccess: 'Аватарът е изтрит успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете този аватар?',
    backToAvatars: 'Към аватарите',
    maxCategoriesLength: 'Максимално {number} категории!'
  },
  avatarCategories: {
    categories: 'Категории аватари',
    createCategory: 'Добави категория',
    editCategory: 'Промени категория',
    createSuccess: 'Категорията е създадена усепшно!',
    updateSuccess: 'Категорията е променена успешно!',
    deleteSuccess: 'Категорията е изтрита успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете този категория?'
  },
  gemsPlans: {
    gemsPlans: 'Gems планове',
    createPlan: 'Добави план',
    editPlan: 'Промени план',
    createSuccess: 'Планът е създаден усепшно!',
    updateSuccess: 'Планът е променен успешно!',
    deleteSuccess: 'Планът е изтрит успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете този план?'
  },
  pushNotifications: {
    pushNotifications: 'Пуш нотификации',
    createPushNotification: 'Добави нотификация',
    editPushNotification: 'Промени нотификация',
    createSuccess: 'Нотификацията е създаден успешно!',
    updateSuccess: 'Нотификацията е променен успешно!',
    deleteSuccess: 'Нотификацията е изтрит успешно!',
    deleteConfirmText: 'Сигурни ли сте, че искате да изтриете нотификацията?',
    sendNotification: 'Изпрати нотификацията',
    sendConfirmText:
      'Сигурни ли сте, че искате да изпратите тази нотификация до всички потребители? Тази операция може да отнеме повече време!',
    cannotSendInProgressNotification:
      'Не може да изпратите нотификацията, която е в Прогрес',
    backToPushNotifications: 'Към нотификациите'
  },
  errors: {
    somethingWentWrong:
      'Имаме техниечески проблеми! Нашият екип работи по отстраняването им. Благодарим Ви за търпението!',
    wrongCredentials: 'Грешен имейл адрес / парола!',
    invalidData: 'Невалидни данни!',
    notAuthorized: 'Отказан достъп!',
    cannotChangeSuperAdminRole: 'Не може да променяте ролята на супер админ!',
    passwordsMismatch: 'Паролите не съвпадат!',
    userNotFound: 'Потребителят не беше намерен!',
    incorrectOldPassword: 'Старата парола е грешна!',
    invalidQueryParams: 'Невалидни параметри при заявката!',
    cannotDeleteSuperAdminUser: 'Не може да изтриете супер админ потребител!',
    yourAccountIsLocked:
      'Вашият потребител е заключен! Моля свържете се с човек от нашият екип за повече информация.',
    cannotLockSuperAdminUser: 'Не може да заключите супер админ потребител!',
    userAlreadyLocked: 'Потребителят е вече заключен!',
    userIsNotLocked: 'Потребителят не е заключен!',
    alreadyExistDefaultBoard: 'Вече има борд по подразбиране!',
    gamPushNotificationNotFound: 'Нотификацията не е намерена!',
    cannotUpdateInProgressNotification:
      'Не може да промените нотификацията, която е в Прогрес',
    cannotDeleteInProgressNotification:
      'Не може да изтриете нотификацията, която е в Прогрес',
    cannotSendInProgressNotification:
      'Не може да изпратите нотификацията, която е в Прогрес'
  }
};
