import Vue from 'vue';
import Router from 'vue-router';
import authGuard from './core/guards/auth.guard';
import publicRoutes from './modules/public/routes';
import usersRoutes from './modules/users/routes';
import tasksRoutes from './modules/tasks/routes';
import quotesRoutes from './modules/quotes/routes';
import factsRoutes from './modules/facts/routes';
import questionsRoutes from './modules/questions/routes';
import boardsRoutes from './modules/boards/routes';
import figuresRoutes from './modules/figures/routes';
import avatarsRoutes from './modules/avatars/routes';
import avatarCategoriesRoutes from './modules/avatar-categories/routes';
import gemsPlansRoutes from './modules/gems-plans/routes';
import pushNotificationsRoutes from './modules/push-notifications/routes';
import specialBonusesRoutes from './modules/special-bonuses/routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...publicRoutes,
    ...usersRoutes,
    ...tasksRoutes,
    ...quotesRoutes,
    ...factsRoutes,
    ...questionsRoutes,
    ...boardsRoutes,
    ...figuresRoutes,
    ...avatarsRoutes,
    ...avatarCategoriesRoutes,
    ...gemsPlansRoutes,
    ...pushNotificationsRoutes,
    ...specialBonusesRoutes
  ]
});

router.beforeEach(authGuard);

export default router;
