<template>
  <v-app id="app" v-bind:class="{ 'app-container': isAuthenticated }">
    <notifications classes="pa-3 app-notifications" />
    <app-modal ref="modal"></app-modal>
    <div v-if="isAuthenticated">
      <side-navbar></side-navbar>
      <app-navbar></app-navbar>
      <v-main>
        <router-view />
      </v-main>
    </div>
    <div v-else>
      <public-navbar></public-navbar>
      <v-main>
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import PublicNavbar from './modules/shared/components/PublicNavbar';
import AppNavbar from './modules/shared/components/AppNavbar';
import SideNavbar from './modules/shared/components/SideNavbar';
import AppModal from './modules/shared/components/AppModal';

export default {
  name: 'App',
  components: {
    PublicNavbar,
    AppNavbar,
    SideNavbar,
    AppModal
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  mounted() {
    this.$root.$modal = this.$refs.modal;
  }
};
</script>

<style lang="scss">
@import './assets/styles/index.scss';
</style>
