import { GET_TAGS, SET_TAGS } from './types';
import tagsHttpService from '../services/tags.http.service';

export default {
  async [GET_TAGS]({ commit }) {
    const { result } = await tagsHttpService.index();

    commit(SET_TAGS, result);
  }
};
