import {
  SET_ACL,
  SET_PROFILE,
  SET_USERS,
  UPDATE_USER,
  UPDATE_PROFILE,
  DELETE_USER
} from './types';
import authSession from '../../../core/services/auth.session.service';

export default {
  [SET_ACL](state, payload) {
    state.acl = payload;
  },
  [SET_PROFILE](state, payload) {
    state.currentUser = payload;
  },
  [SET_USERS](state, payload) {
    state.users = payload.users;
    state.totalUsers = payload.totalCount;
  },
  [UPDATE_USER](state, user) {
    const index = state.users.findIndex(u => u.id === user.id);

    if (index !== -1) {
      state.users.splice(index, 1, {
        ...state.users[index],
        ...user
      });
    }
  },
  [UPDATE_PROFILE](state, payload) {
    const updatedUser = {
      ...state.currentUser,
      ...payload
    };
    authSession.setUser(updatedUser);
    authSession.setLang(payload.lang);
    state.currentUser = updatedUser;
  },
  [DELETE_USER](state, id) {
    const index = state.users.findIndex(t => t.id === id);

    if (index !== -1) {
      state.users.splice(index, 1);
    }
  }
};
