import {
  GET_TASKS,
  SET_TASKS,
  ADD_TASK,
  UPDATE_TASK,
  DELETE_TASK
} from './types';
import tasksHttpService from '../services/tasks.http.service';

function mapTaskTags(allTags, selectedTags) {
  const result = [];

  for (const tagId of selectedTags) {
    result.push(allTags.find(tag => tag.id === tagId));
  }

  return result;
}

export default {
  async [GET_TASKS]({ commit }, params) {
    const { result } = await tasksHttpService.index(params);

    commit(SET_TASKS, result);
  },
  async [ADD_TASK](actions, payload) {
    await tasksHttpService.create(payload);
  },
  async [UPDATE_TASK]({ commit, rootState }, payload) {
    const { id, ...task } = payload;

    await tasksHttpService.update(id, task);
    commit(UPDATE_TASK, {
      ...payload,
      tags: mapTaskTags(rootState.tags.tags, task.tags)
    });
  },
  async [DELETE_TASK]({ commit }, payload) {
    await tasksHttpService.del(payload);

    commit(DELETE_TASK, payload);
  }
};
