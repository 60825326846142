import localStorageService from './local.storage.service';

const USER_TOKEN = 'aut';
const CURRENT_USER = 'acu';
const USER_LANG = 'lang';

function getUser() {
  return JSON.parse(localStorageService.getItem(CURRENT_USER));
}

function getToken() {
  return localStorageService.getItem(USER_TOKEN);
}

function getLang() {
  return localStorageService.getItem(USER_LANG);
}

function setUser(user) {
  localStorageService.setItem(CURRENT_USER, JSON.stringify(user));
}

function setToken(token) {
  localStorageService.setItem(USER_TOKEN, token);
}

function setLang(lang) {
  localStorageService.setItem(USER_LANG, lang);
}

function removeUser() {
  localStorageService.removeItem(CURRENT_USER);
}

function removeToken() {
  localStorageService.removeItem(USER_TOKEN);
}

function isAuthenticated() {
  return !!this.getToken();
}

export default {
  getUser,
  getToken,
  getLang,
  setUser,
  setToken,
  setLang,
  removeUser,
  removeToken,
  isAuthenticated
};
