import { SET_TASKS, UPDATE_TASK, DELETE_TASK } from './types';

export default {
  [SET_TASKS](state, payload) {
    state.tasks = payload.tasks;
    state.totalTasks = payload.totalCount;
  },
  [UPDATE_TASK](state, task) {
    const index = state.tasks.findIndex(t => t.id === task.id);

    if (index !== -1) {
      state.tasks.splice(index, 1, {
        ...state.tasks[index],
        ...task
      });
    }
  },
  [DELETE_TASK](state, id) {
    const index = state.tasks.findIndex(t => t.id === id);

    if (index !== -1) {
      state.tasks.splice(index, 1);
    }
  }
};
