import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  tasks: [],
  totalTasks: 0
};

export default {
  state,
  actions,
  mutations,
  getters
};
